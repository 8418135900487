<template>
  <b-navbar
    toggleable="md"
    type="light"
  >
    <!-- toggle button -->
    <b-navbar-toggle
      class="ml-auto"
      target="nav-text-collapse"
    >
      <feather-icon
        icon="AlignJustifyIcon"
        size="21"
      />
    </b-navbar-toggle>
    <!--/ toggle button -->

    <!-- collapse -->
    <b-collapse
      id="nav-text-collapse"
      is-nav
    >
      <b-tabs
        pills
        class="mt-1 mt-md-0"
        nav-class="mb-0"
      >
        <template #tabs-start>
          <b-nav-item
            :active="!$route.query.tab || $route.query.tab === '/user/:id'"
            class="font-weight-bold"
            @click="$router.replace({ name: 'apps-users-view', params: { id: $route.params.id } })"
          >
            <span class="d-none d-md-block">Рефералы</span>
            <feather-icon
              icon="InfoIcon"
              class="d-block d-md-none"
            />
          </b-nav-item>
          <b-nav-item
            :active="$route.query.tab === 'orders'"
            class="font-weight-bold"
            @click="$router.replace({ name: 'apps-users-view', params: { id: $route.params.id }, query: { tab: 'orders' } })"
          >
            <span class="d-none d-md-block">Продажи</span>
            <feather-icon
              icon="PieChartIcon"
              class="d-block d-md-none"
            />
          </b-nav-item>
          <b-nav-item
            :active="$route.query.tab === 'bonuses'"
            class="font-weight-bold"
            @click="$router.replace({ name: 'apps-users-view', params: { id: $route.params.id }, query: { tab: 'bonuses' } })"
          >
            <span class="d-none d-md-block">Движение бонусов</span>
            <feather-icon
              icon="PieChartIcon"
              class="d-block d-md-none"
            />
          </b-nav-item>
          <b-nav-item
            :active="$route.query.tab === 'payments'"
            class="font-weight-bold"
            @click="$router.replace({ name: 'apps-users-view', params: { id: $route.params.id }, query: { tab: 'payments' } })"
          >
            <span class="d-none d-md-block">Выплаты</span>
            <feather-icon
              icon="PieChartIcon"
              class="d-block d-md-none"
            />
          </b-nav-item>
          <b-nav-item
            :active="$route.query.tab === 'basket'"
            class="font-weight-bold"
            @click="$router.replace({ name: 'apps-users-view', params: { id: $route.params.id }, query: { tab: 'basket' } })"
          >
            <span class="d-none d-md-block">Корзина</span>
            <feather-icon
              icon="PieChartIcon"
              class="d-block d-md-none"
            />
          </b-nav-item>
        </template>
      </b-tabs>

    </b-collapse>
    <!--/ collapse -->
  </b-navbar>
</template>

<script>
import {
  BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem,
} from 'bootstrap-vue'

export default {
  components: {
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
  },
}
</script>
