<template>
  <div class="sale-admin">

    <b-alert
      v-if="errorData"
      variant="danger"
      :show="errorData"
    >
      <h4 class="alert-heading">
        Ошибка при извлечении данных
      </h4>
      <div class="alert-body">
        Вернитесь
        <b-link
          class="alert-link"
          :to="{ name: 'user-list'}"
        >
          к списку
        </b-link>
        пользователей.
      </div>
    </b-alert>

    <div v-if="profile && !errorData">
      <b-row>
        <b-col
          cols="12"
        >
          <b-card
            header="Профиль пользователя"
            header-tag="h2"
            header-class="border-bottom"
          >

            <b-row class="d-flex pt-50">
              <b-col
                lg="3"
                xl="3"
                md="4"
                class="pt-50"
              >
                <b-media>
                  <template #aside>
                    <b-avatar
                      rounded="lg"
                      size="230"
                      :src="profileAvatar ? profileAvatar.path : null"
                      :text="avatarText(`${profile.firstName} ${profile.lastName || ''}`)"
                      :variant="`light-${generateRandomVariant()}`"
                    />
                  </template>
                </b-media>

              </b-col>
              <b-col
                class="user-right-info-block d-flex justify-content-center pt-50"
              >
                <b-col
                  class="d-flex flex-column justify-content-start"
                >
                  <div class="d-flex justify-content-end">
                    <b-navbar
                      toggleable="md"
                      type="light"
                      class="p-0"
                    >
                      <b-navbar-toggle
                        class="ml-auto"
                        target="nav-text-collapse"
                      >
                        <feather-icon
                          icon="AlignJustifyIcon"
                          size="21"
                        />
                      </b-navbar-toggle>

                      <b-collapse
                        id="nav-text-collapse"
                        is-nav
                      >
                        <b-tabs
                          pills
                          class="mt-1 mt-md-0"
                          nav-class="mb-0"
                        >
                          <template #tabs-start>
                            <b-nav-item
                              :active="block === 'profile'"
                              class="font-weight-bold"
                              @click="block = 'profile'"
                            >
                              <span class="d-none d-md-block">профиль</span>
                              <feather-icon
                                icon="InfoIcon"
                                class="d-block d-md-none"
                              />
                            </b-nav-item>
                            <b-nav-item
                              :active="block === 'company'"
                              class="font-weight-bold"
                              @click="block = 'company'"
                            >
                              <span class="d-none d-md-block">о работе</span>
                              <feather-icon
                                icon="InfoIcon"
                                class="d-block d-md-none"
                              />
                            </b-nav-item>
                          </template>
                        </b-tabs>

                      </b-collapse>
                    </b-navbar>
                  </div>

                  <profile-data-block
                    v-if="block === 'profile'"
                    :profile="profile"
                  />
                  <profile-company-data-block
                    v-if="block === 'company' && userCompany"
                    :user-company="userCompany"
                  />

                </b-col>
                <b-col
                  lg="3"
                  xl="3"
                  md="4"
                >
                  <div class="text-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="createOrderStartBonus($route.params.id, refetchData)"
                    >
                      Акция - Скачай и зарабатывай
                    </b-button>
                  </div>
                </b-col>

              </b-col>
              <b-col cols="12">

                <bonuses-card
                  v-if="bonusesList"
                  :data="bonusesList"
                  :is-client="false"
                />

              </b-col>
            </b-row>
          </b-card>

        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card
            no-body
            class="mb-1 p-1"
          >
            <profile-card-menu :key="$route.query.tab" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <component
            :is="tabs"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BBadge, BLink, BAvatar, BMedia, BOverlay, BAlert, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@/libs/helper'
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import { fetchUserProfile } from '@/application/userService'
import { createOrderStartBonus } from '@/application/userMotivationService'
import { watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { generateRandomVariant } from '@/utils/UserFilter'

import BonusesCard from '@/components/bonus/BonusesCard.vue'
import ProfileCardMenu from './components/ProfileCardMenu.vue'
import ProfileDataBlock from './components/ProfileDataBlock.vue'
import ProfileCompanyDataBlock from './components/ProfileCompanyDataBlock.vue'

const Referrals = () => import('./components/ProfileReferrals.vue')
const Orders = () => import('./components/ProfileOrders.vue')
const Bonuses = () => import('./components/ProfileBonusMovements.vue')
const Payments = () => import('./components/ProfilePayments.vue')
const Basket = () => import('./components/ProfileBasket.vue')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    ProfileCardMenu,
    BOverlay,
    BAlert,
    ProfileDataBlock,
    ProfileCompanyDataBlock,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    BonusesCard,

    Referrals,
    Orders,
    Bonuses,
    Payments,
    Basket,

  },
  directives: {
    Ripple,
  },
  data() {
    return { block: 'profile' }
  },
  computed: {
    tabs() {
      if (!this.$route.query.tab || this.$route.query.tab === 'referrals') {
        return 'referrals'
      }
      return this.$route.query.tab
    },
  },
  setup() {
    const {
      getElement, profile, userCompany, profileAvatar, bonusesList, refetchData, errorData,
    } = fetchUserProfile()
    getElement(parseInt(router.currentRoute.params.id, 10))

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      () => refetchData(),
    )
    return {
      profile,
      errorData,
      userCompany,
      profileAvatar,
      bonusesList,

      formatDate,
      avatarText,
      generateRandomVariant,
      createOrderStartBonus,
      refetchData,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.sale-admin .v-select {
  min-width: 350px;
}
.sale-admin .v-select.vs--single .vs__selected {
  width: 90%;
}

.item-info {
  margin-bottom: 10px;
}

.user-right-info-block {
@media(max-width:770px) {
    flex-direction: column;
  }
}
</style>
