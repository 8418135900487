<template>
  <b-row class="d-flex flex-column p-1">

    <b-col class="d-flex justify-content-between">

      <div class="block-left">

        <h4 class="text-primary">
          {{ profile.firstName }} {{ profile.lastName || '' }}
        </h4>

      </div>
      <div class="block-right">

        <span>{{ profile.phone }}</span>

        <feather-icon
          :icon="resolveUserRoleIcon(profile.role)"
          size="18"
          class="ml-1"
          :class="`text-${resolveUserRoleVariant(profile.role)}`"
        />
        <span class="ml-50">{{ profile.role }}</span>

      </div>
    </b-col>
    <b-col class="d-flex justify-content-between">
      <div class="block-left">

        <h4>
          E-mail
        </h4>

      </div>
      <div class="block-right">

        <span>{{ profile.email }}</span>

        <feather-icon
          v-if="profile.email && String(profile.email.length).trim() > 0"
          :icon="verifEmailIcon(profile.verifEmail)"
          size="18"
          class="ml-1"
          :class="`text-${verifEmailVariant(profile.verifEmail)}`"
        />

      </div>
    </b-col>
    <b-col class="d-flex justify-content-between">
      <div class="block-left">

        <h4>
          Зарегистрирован
        </h4>

      </div>
      <div class="block-right">

        <span>{{ formatDate(profile.createdAt) }}</span>

      </div>
    </b-col>
    <b-col class="d-flex justify-content-between">
      <div class="block-left">

        <h4>
          Последний вход
        </h4>

      </div>
      <div class="block-right">

        <span>{{ formatDate(profile.lastLogin) }}</span>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import {
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  verifEmailVariant,
  verifEmailIcon,
} from '@/utils/UserFilter'
import { formatDate } from '@/libs/helper'

export default {
  components: {
    BRow, BCol,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      verifEmailVariant,
      verifEmailIcon,
      formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.block-left {
  @media(max-width:700px) {
    font-size: 12px;
  }
  font-size: 15px;
}
.block-right {
  @media(max-width:900px) {
    padding-left: 5px;
  }
  @media(max-width:700px) {
    font-size: 12px;
    font-weight: bold;
  }
  font-size: 15px;
}
</style>
