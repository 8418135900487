<template>
  <b-row class="d-flex flex-column p-1">

    <b-col class="d-flex justify-content-between">

      <div class="block-left">

        <h4>
          ИНН
        </h4>

      </div>
      <div class="block-right">

        <span>{{ userCompany.taxNumber }}</span>

      </div>
    </b-col>
    <b-col class="d-flex justify-content-between">
      <div class="block-left">

        <h4>
          Организация
        </h4>

      </div>
      <div class="block-right">

        <span>{{ userCompany.name }}</span>

      </div>
    </b-col>
    <b-col class="d-flex justify-content-between">
      <div class="block-left">

        <h4>
          Адрес
        </h4>

      </div>
      <div class="block-right">

        <span>{{ userCompany.address }}</span>

      </div>
    </b-col>
    <b-col class="d-flex justify-content-between">
      <div class="block-left">

        <h4>
          Торговая точка
        </h4>

      </div>
      <div class="block-right">

        <span>{{ userCompany.departmentStore }}</span>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol,
  },
  props: {
    userCompany: {
      type: Object,
      requred: true,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.block-left {
  font-weight: 600;
  font-size: 15px;
}
.block-right {
  font-size: 15px;
}
</style>
