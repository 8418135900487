<template>
  <div class="bonus-statistics">
    <b-card
      v-if="data"
      no-body
      class="card-bonus-statistics mb-0"
    >

      <b-card-header>
        <b-card-title>Статистика счета</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in items"
            :key="item.icon"
            xl="3"
            sm="6"
            xs="12"
            :class="item.customClass"
          >

            <b-media
              no-body
              class="justify-content-center"
            >
              <b-media-aside
                class="mr-50 ml-50"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data[item.type] }}B
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        update: null,
        accruals: 50,
        payout: 0,
        review: 0,
        available: 0,
        availableAccept: 0,
      }),
    },
    isClient: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const availableBonus = ref()

    if (this.isClient) availableBonus.value = 'available'
    else {
      availableBonus.value = 'availableAccept'
    }

    return {
      items: [{
        icon: 'GiftIcon',
        customClass: 'mb-2 mb-xl-0',
        color: 'light-primary',
        type: 'accruals',
        subtitle: 'Всего начислений',
      }, {
        icon: 'ShoppingBagIcon',
        customClass: 'mb-2 mb-xl-0',
        color: 'light-info',
        type: 'payout',
        subtitle: 'Всего выплачено',
      }, {
        icon: 'ClipboardIcon',
        customClass: 'mb-2 mb-sm-0',
        color: 'light-danger',
        type: 'review',
        subtitle: 'На рассмотрении',
      }, {
        icon: 'BoldIcon',
        customClass: '',
        color: 'light-success',
        type: availableBonus.value,
        subtitle: 'Доступно',
      }],
    }
  },
}

</script>
<style lang="scss" scoped>
  .bonus-statistics {
    @media(max-width:575.98px) {
      padding: 0 0;
    }
    .card-bonus-statistics {
      border-radius: 15px;
      img {
        height: 25px;
      }

      .text-balance {
        color: #ea5455;
        font-weight: 600;
        font-size: 30px;
        line-height: 39px;
      }
      .text-bonus {
        color: #ea5455;
        font-weight: 300;
        font-size: 14px;
        line-height: 10px;
      }
    }
  }

</style>
